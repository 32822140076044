const en = {
  menu: {
    about: "About",
    admin: "Admin",
    confirm: "Attention: Are you sure you want to return to the beginning? All data you entered will be lost.",
    language: "Select your language",
    logout: "Logout",
    voice: {
      headerName: "Voice",
      confirm: "Attention: Are you sure you want to start with the vocal tasks? ",
    },
    questionnaires: {
      headerName: "Questionnaires",
      confirm: "Attention: Are you sure you want to start with the questionnaires? ",
    },
    footer: {
      information: "Informations",
    },
  },
  project: {
    clickToStart: "Start",
    goToNextStep: "Continue",
    questionnaireIDFootnote: "Patient ID:",
    consent: {
      title: "Consent",
      accept: "I acknowledge that I have read this document, accept its terms and therefore do not object to the Research.",
      date: "Date:",
      continue: "Validate",
    },
    usertype: {
      newUser: "This is my first participation",
      existingUser: "I have already participated and I want to participate again",
    },
    newUser: {
      title: "Please enter your email address",
      explication:
        "No message will be sent to the e-mail address you provide. It will only be used to retrieve your data for future " +
        "participation in the study or if you wish to exercise your rights.",
      //email: "To receive it, please indicate your email address below",
      //userid: "Code",
      //privacy: "Note: your email address will not be stored, it will only be used to send you this code.",
      //send: "Send code",
      continue: "Continue",
    },
    existingUser: {
      title: "Please log in with your email address",
      explication:
        "No message will be sent to the email address you have given us. It is only used to retrieve your historical" +
        " data and to enable us to apply any wishes you may have regarding the exercise of your rights.",
      continue: "Continue",
      error: "The user with this email address does not exist yet.",
      isVerifying: "Email address is currently being verified..",
      //forgot: "I forgot my code",
    },
    phoneNumber: {
      title: "Please enter your mobile phone number",
      explication:
        "You will receive an SMS allowing you to complete this sample in the waiting room. You will receive a similar " +
        "SMS in 1 week to provide a new sample from your home, and so on until the end of the study.",
      continue: "Confirmer",
    },
    recording: {
      title: "Vocal tasks",
      instructions: {
        title: "Instructions",
        first: "Find a quiet environment",
        second: "Try to keep a constant distance from your mouth to your recording device during the vocal tasks",
        third: "Follow the instructions given for the vocal tasks",
        continue: "Continue",
      },
      tasks: "Reminding steps :",
    },
    questionnaire: {
      title: "Questionnaire",
      description: "Answer the following questions",
    },
    done: {
      questionnairesNext: {
        title: "This part is over. Thanks!",
        continue: "Continue with questionnaires",
      },
      recordingsNext: {
        title: "This part is over. Thanks! Please put the headset on your head now.",
        titleProjectLongitudinal: "This part is over.",
        continue: "Continue with vocal tasks",
      },
      information:
        "If you have any questions about the study, an 'Information' menu is " +
        "available at the bottom of each page, starting on the next page.",
    },
    final: {
      title: "The study is finished, thank you for participating!",
    },
  },
  audioplayer: {
    pause: "Pause",
    stop: "Stop",
    listen: "Listen",
    record: "Record",
    recordAgain: "Record Again",
    instruction: "You can go to the next step once you have finished the task",
    rerecord: "If you like, you can re-record the audio or go to the next step",
  },
  admin: {
    title: "Questionnaire administration",
    select: "Select a patient ID to complete the questionnaire",
    table: {
      idColumnName: "ID",
      questionnairesColumnName: "Quest.",
      recordingsColumnName: "Record.",
      doctorsColumnName: "Doctor",
      completeButton: "complete",
    },
  },
}

export default en
