import { default as speakuityC1 } from "./speakuityC1"
import { default as speakuityR1 } from "./speakuityR1"
import { default as speakuityE1 } from "./speakuityE1"
import { default as speakuityMH3 } from "./speakuityMH3"
import { Project } from "../@types/project"
import { default as speakuityMHDemo } from "./speakuityMH-demo"
import { default as speakuityMH2 } from "./speakuityMH2"
import { default as speakuityO1 } from "./speakuityO1"

const projects: Project[] = [speakuityC1, speakuityR1, speakuityE1, speakuityMHDemo, speakuityMH2, speakuityMH3, speakuityO1]

export default projects
