const de = {
  menu: {
    about: "Impressum",
    admin: "Admin",
    confirm: "Achtung: Möchten Sie wirklich zum Anfang zurückkehren? Alle eingegebenen Daten gehen verloren.",
    language: "Wählen Sie Ihre Sprache",
    logout: "Logout",
    voice: {
      headerName: "Stimme",
      confirm: "Achtung! Sind Sie sicher, dass Sie mit den Sprachtests beginnen wollen? ",
    },
    questionnaires: {
      headerName: "Fragebögen",
      confirm: "Achtung! Sind Sie sicher, dass Sie mit den Fragebögen beginnen wollen? ",
    },
    footer: {
      information: "Informationen",
    },
  },
  project: {
    clickToStart: "Starten",
    goToNextStep: "Weiter",
    questionnaireIDFootnote: "ID Patient:",
    consent: {
      title: "Einwilligungserklärung",
      accept:
        "Ich bestätige, dass ich dieses Dokument gelesen habe, akzeptiere die Bedingungen und habe daher keine " +
        "Einwände gegen die Forschung.",
      date: "Datum:",
      continue: "Bestätigen",
    },
    usertype: {
      newUser: "Dies ist meine erste Teilnahme",
      existingUser: "Ich habe bereits teilgenommen und möchte erneut teilnehmen",
    },
    newUser: {
      title: "Bitte geben Sie Ihre E-Mail-Adresse an",
      explication:
        "An die von Ihnen angegebene E-Mail-Adresse werden keine Nachrichten gesendet. Sie wird nur verwendet, um Ihre Daten" +
        " bei einer erneuten Teilnahme an der Studie abzurufen oder wenn Sie Ihre Rechte ausüben möchten.",
      //email: "Um ihn zu erhalten, geben Sie bitte unten Ihre E-Mail-Adresse an.",
      //userid: "Code",
      //privacy: "Hinweis: Ihre E-Mail-Adresse wird nicht gespeichert, sie wird nur dazu verwendet, Ihnen diesen Code zu schicken.",
      //send: "Code senden",
      continue: "Weiter",
    },
    existingUser: {
      title: "Bitte authentifizieren Sie sich mit Ihrer E-Mail-Adresse",
      explication:
        "An die von Ihnen angegebene E-Mail-Adresse werden keine Nachrichten gesendet. Sie wird nur verwendet, " +
        "um Ihre historischen Daten zu finden und uns zu ermöglichen, Ihre eventuellen Wünsche bezüglich der Ausübung " +
        "Ihrer Rechte umzusetzen.",
      continue: "Weiter",
      //forgot: "Ich habe meinen Code vergessen",
      error: "Der Benutzer mit dieser E-Mail-Adresse existiert noch nicht.",
      isVerifying: "E-Mail-Adresse wird derzeit überprüft...",
    },
    phoneNumber: {
      title: "Bitte geben Sie Ihre Mobilfunknummer an",
      explication:
        "Sie werden eine SMS erhalten, mit der Sie diese Probe im Wartezimmer ausfüllen können. In 1 Woche erhalten Sie" +
        " eine ähnliche SMS, um eine weitere Probe von zu Hause aus zu liefern, und so weiter bis zum Ende der Studie.",
      continue: "Confirmer",
    },
    recording: {
      title: "Sprachtests",
      instructions: {
        title: "Anleitung",
        first: "Finden Sie eine möglichst ruhige Umgebung",
        second: "Versuchen Sie, während der Sprachtests einen konstanten Abstand von Ihrem Mund zu Ihrem Aufnahmegerät zu halten",
        third: "Befolgen Sie die Anweisungen für die Sprachtests",
        continue: "Weiter",
      },
      tasks: "Verbleibende Aufgaben :",
    },
    questionnaire: {
      title: "Fragebögen",
      description: "Beantworten Sie folgende Fragen",
    },
    done: {
      questionnairesNext: {
        title: "Sie haben diese Studie abgeschlossen, wir danken Ihnen für Ihre Teilnahme.",
        continue: "Fortfahren mit Fragebogen",
      },
      recordingsNext: {
        title:
          "Sie haben diese Studie abgeschlossen, wir danken Ihnen für Ihre Teilnahme. Bitte setzen Sie nun den Kopfhörer auf Ihren Kopf.",
        titleProjectLongitudinal: "Sie haben diese Studie abgeschlossen.",
        continue: "Fortfahren mit Sprachtests",
      },
      information:
        "Wenn Sie Fragen zur Studie haben, steht Ihnen ab der nächsten Seite unten" +
        " auf jeder Seite ein Menü 'Informationen' zur Verfügung.",
    },
    final: {
      title: "Die Studie ist abgeschlossen, vielen Dank für Ihre Teilnahme!",
    },
  },
  audioplayer: {
    pause: "Pause",
    stop: "Stop",
    listen: "Anhören",
    record: "Aufnehmen",
    recordAgain: "Nochmals aufnehmen",
    instruction: "Sie können mit dem nächsten Schritt fortfahren, sobald Sie die Aufgabe abgeschlossen haben.",
    rerecord: "Wenn Sie möchten, können Sie den Ton erneut aufnehmen oder mit dem nächsten Schritt fortfahren",
  },
  admin: {
    title: "Fragebogen Verwaltung",
    select: "Wählen Sie eine Patienten-ID aus, um den Fragebogen auszufüllen",
    table: {
      idColumnName: "ID",
      questionnairesColumnName: "Quest.",
      recordingsColumnName: "Record.",
      doctorsColumnName: "Doctor",
      completeButton: "ausfüllen",
    },
  },
}

export default de
