// @mui
import { styled, useTheme } from "@mui/material/styles"
import { Box, AppBar, Toolbar, Container } from "@mui/material"
// hooks
// utils
import cssStyles from "../../utils/cssStyles"
// config
import { HEADER } from "../../config"
// components
import Logo from "../../components/Logo"
import MenuDesktop from "./MenuDesktop"
import AdminHeaderConfig from "./AdminHeaderConfig"
import useOffSetTop from "../../hooks/useOffSetTop"
//import { NavigationType, useNavigationType } from "react-router-dom"
import React from "react"
//import { GeneralAnswers } from "../../@types/patient"

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(["height", "background-color"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("md")]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}))

const ToolbarShadowStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: "auto",
  borderRadius: "50%",
  position: "absolute",
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}))

// ----------------------------------------------------------------------

export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT)
  const theme = useTheme()
  //const { id } = useParams()
  //const [showAdmin, setShowAdmin] = useState(true)
  //const [project, setProject] = useState<Project>()

  //const project = projects.find((project) => project.projectID === id) ?? ""
  //console.log("-- start Mainheader --")
  // useEffect(() => {
  //   if (project && project.projectType != "oneshot") {
  //     setShowAdmin(false)
  //   }
  // }, [])

  // useEffect(() => {
  //   console.log("id:", id)
  //   setProject(projects.find((project) => project.projectID === id))
  // }, [id])

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: "transparent" }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Logo />
          <Box sx={{ flexGrow: 1 }} />
          <MenuDesktop isOffset={isOffset} navConfig={AdminHeaderConfig()} />
          {/*{project && project.projectType == "oneshot" && <MenuDesktop isOffset={isOffset} navConfig={AdminHeaderConfig()} />}{" "}*/}
          {/*{showAdmin && <MenuDesktop isOffset={isOffset} navConfig={AdminHeaderConfig()} />}{" "}*/}
        </Container>
      </ToolbarStyle>
      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  )
}
