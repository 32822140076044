import tasksRecording from "./tasks"
import { Project } from "../../@types/project"

const project: Project = {
  projectID: "60e574b1-dbc8-4ef1-8af5-f446642578d1",
  projectName: "Speakuity O1",
  projectLocation: "O1",
  projectCreated: "26/01/2024",
  projectType: "oneshot",
  defaultLang: "fr",
  projectLang: ["fr"],
  voiceRecordings: tasksRecording,
  questionnaires: undefined,
  // questionnaires: [
  //   { questionnaire: general, optional: false },
  //   { questionnaire: PHQ, optional: false },
  //   { questionnaire: GAD, optional: false },
  //   { questionnaire: fss, optional: false },
  //   { questionnaire: cofounding, optional: false },
  // ],
  diseaseQuestions: [],
  consents: undefined,
  isPatientAnsweringDiseaseQuestions: false,
  showOptionToStartWithNonDefaultTasks: false,
  isStartWithQuestionnaires: false,
  isAskPhoneNumber: false,
  showQuestionnaireIdFinalPage: false,
  showVocalTaskInstructions: true,
  versionData: "1",
  transparenceID: "upp421",
}

export default project
